import React, { useState } from "react";
import { Mail, X } from "lucide-react";

type LetterPhase = 0 | 1 | 2 | 3;

interface LetterStyles {
  transform: string;
  opacity: number;
  zIndex: number;
}

const ChristmasLetterPage: React.FC = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTopFlipOpen, setIsTopFlipOpen] = useState(false);
  const [letterPhase, setLetterPhase] = useState<LetterPhase>(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleEnvelopeClick = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    if (!isFlipped) {
      setIsFlipped(true);
      setTimeout(() => {
        setIsTopFlipOpen(true);
        setTimeout(() => {
          setLetterPhase(1);
          setTimeout(() => {
            setLetterPhase(2);
            setTimeout(() => {
              setLetterPhase(3);
              setIsAnimating(false);
            }, 500);
          }, 500);
        }, 300);
      }, 1500);
    } else {
      setLetterPhase(2);
      setTimeout(() => {
        setLetterPhase(1);
        setTimeout(() => {
          setLetterPhase(0);
          setTimeout(() => {
            setIsTopFlipOpen(false);
            setTimeout(() => {
              setIsFlipped(false);
              setIsAnimating(false);
            }, 1500);
          }, 500);
        }, 500);
      }, 500);
    }
  };

  const getLetterStyles = (phase: LetterPhase): LetterStyles => {
    switch (phase) {
      case 0:
        return {
          transform: "translateY(0)",
          opacity: 0,
          zIndex: 0,
        };
      case 1:
        return {
          transform: "translateY(-15%)",
          opacity: 1,
          zIndex: 1,
        };
      case 2:
        return {
          transform: "translateY(-80%) rotate(6deg)",
          opacity: 1,
          zIndex: 1,
        };
      case 3:
        return {
          transform: "translateY(-40%) rotate(0deg)",
          opacity: 1,
          zIndex: 3,
        };
    }
  };

  return (
    <div className="min-h-screen bg-stone-900">
      <div className="container mx-auto px-6">
        <div className="min-h-screen flex items-center justify-center">
          <div
            className="relative w-full max-w-2xl aspect-[1.618/1] mx-auto -mt-12 md:mt-32"
            style={{ perspective: "2500px" }}
          >
            <div
              className="absolute inset-0 cursor-pointer"
              style={{
                transformStyle: "preserve-3d",
                transition: "transform 1.5s cubic-bezier(0.4, 0, 0.2, 1)",
                transform: isFlipped
                  ? "rotateY(180deg) translateZ(2px)"
                  : "rotateY(0deg) translateZ(2px)",
              }}
              onClick={handleEnvelopeClick}
            >
              <div
                className="absolute inset-0"
                style={{
                  backfaceVisibility: "hidden",
                  transform: "rotateY(0deg)",
                  transition:
                    "transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.75s",
                  opacity: isFlipped ? 0 : 1,
                  transitionDelay: isFlipped ? "0s" : "0.75s",
                }}
              >
                <div className="absolute inset-0 bg-red-600 rounded-lg shadow-xl p-8">
                  <div className="absolute top-4 left-4 text-white text-sm">
                    <p>Nunya</p>
                    <p>123 Wouldn't You</p>
                    <p>Like to Know, Earth</p>
                  </div>

                  <div className="absolute top-1/3 left-1/2 -translate-x-1/2 text-white text-center">
                    <p className="text-xl font-semibold mt-2">Mister Doom</p>
                    <p>69 Baby Love Street</p>
                    <p>Butt Pinch, Earth</p>
                  </div>

                  <Mail
                    className="absolute bottom-4 right-4 text-white"
                    size={24}
                  />
                </div>
              </div>

              <div
                className="absolute inset-0"
                style={{
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  transition:
                    "transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.75s",
                  opacity: isFlipped ? 1 : 0,
                  transitionDelay: isFlipped ? "0.75s" : "0s",
                }}
              >
                <div className="absolute inset-0 rounded-lg shadow-xl bg-red-700">
                  <div className="absolute inset-0 bg-red-700" />

                  <div
                    className={`absolute inset-0 transition-opacity duration-700
                      ${isTopFlipOpen ? "opacity-100" : "opacity-0"}`}
                  >
                    <div
                      className="absolute inset-x-0 top-0 h-1/2"
                      style={{
                        background: "#ffe4e4",
                        clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                      }}
                    >
                      <div
                        className="absolute inset-0"
                        style={{
                          backgroundImage:
                            "linear-gradient(45deg, #ffcdd2 25%, transparent 25%, transparent 75%, #ffcdd2 75%, #ffcdd2)",
                          backgroundSize: "20px 20px",
                          backgroundPosition: "0 0, 10px 10px",
                          opacity: 0.1,
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="absolute inset-x-4 top-4 bottom-4 bg-white rounded-lg shadow-lg transition-all duration-700 ease-in-out origin-top"
                    style={{
                      ...getLetterStyles(letterPhase),
                      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                    }}
                  >
                    <div className="h-full p-6 text-center">
                      <h1 className="text-md sm:text-3xl font-bold text-red-600">
                        Merry Christmas!
                      </h1>
                      <p className="text-gray-700 sm:mt-4 text-xs text-left sm:text-lg">Mister Doom,</p>
                      <p className="text-gray-600 sm:mt-4 text-xs sm:text-lg mt-0.5">
                        I love you lots and lots. You're my favorite person and
                        I admire everything about you. Just wanted to remind
                        you.
                      </p>
                      <p className="text-gray-600 sm:mt-4 text-xs text-left mt-0.5 sm:text-lg">Millions of kisses,</p>
                      <p className="text-red-600 font-semibold sm:mt-4 mt-0.5 text-xs text-left sm:text-lg ml-6">Nunya</p>
                    </div>
                    {letterPhase === 3 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEnvelopeClick();
                        }}
                        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors"
                      >
                        <X size={24} />
                      </button>
                    )}
                  </div>

                  <div
                    className="absolute top-0 left-0 right-0 h-1/2 preserve-3d"
                    style={{
                      transformStyle: "preserve-3d",
                      zIndex: letterPhase === 0 ? 2 : 0,
                    }}
                  >
                    <div
                      className="absolute inset-0 transition-transform duration-700 ease-in-out origin-top"
                      style={{
                        transform: isTopFlipOpen
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)",
                      }}
                    >
                      <div
                        className="absolute inset-0 backface-hidden"
                        style={{
                          background: "#dc2626",
                          clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                        }}
                      />
                      <div
                        className="absolute inset-0 backface-hidden rotate-y-180"
                        style={{
                          background: "#b91c1c",
                          clipPath: "polygon(0 0, 100% 0, 50% 100%)",
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="absolute bottom-0 left-0 right-0 h-1/2"
                    style={{
                      background: "#dc2626",
                      clipPath: "polygon(50% 0, 0 100%, 100% 100%)",
                      zIndex: 2,
                    }}
                  />

                  <div
                    className="absolute inset-y-0 left-0 w-1/2"
                    style={{
                      background: "#991b1b",
                      clipPath: "polygon(0 0, 100% 50%, 0 100%)",
                      zIndex: 2,
                    }}
                  />

                  <div
                    className="absolute inset-y-0 right-0 w-1/2"
                    style={{
                      background: "#991b1b",
                      clipPath: "polygon(100% 0, 100% 100%, 0 50%)",
                      zIndex: 2,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChristmasLetterPage;
