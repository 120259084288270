import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import Dashboard from './pages/Dashboard';
import ProtectedPage from './pages/ProtectedPage';
import FullMemoryBoard from './pages/MemoryBoard';
import { useAuth } from './contexts/AuthContext';
import SpankBankPage from './pages/SpankBank';
import InventoryPage from './pages/InventoryPage';
import Floggers4ChangePage from './pages/Floggers4ChangePage';
import TrappedGamePage from './pages/TrappedGamePage';
import ChristmasLetterPage from './pages/ChristmasLetterPage';


const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const auth = useAuth();
  return auth.isLoggedIn ? element : <Navigate to="/signin" />;
};

const AppRouter: React.FC = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      await auth.verifyAuth();
      setLoading(false);
    };

    checkAuth();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <Routes>
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
      <Route path="/protected" element={<PrivateRoute element={<ProtectedPage />} />} />
      <Route path="/memories" element={<PrivateRoute element={<FullMemoryBoard />} />} /> 
      <Route path="/spank-bank" element={<PrivateRoute element={<SpankBankPage />} />} />
      <Route path="/inventory" element={<PrivateRoute element={<InventoryPage />} />} />
      <Route path="/floggers4change" element={<PrivateRoute element={<Floggers4ChangePage />} />} />
      <Route path="/trapped-game" element={<PrivateRoute element={<TrappedGamePage />} />} />
      <Route
        path="/christmas-letter"
        element={<PrivateRoute element={<ChristmasLetterPage />} />}
      />

      {/* Default Route */}
      <Route
        path="/"
        element={auth.isLoggedIn ? <Navigate to="/dashboard" /> : <HomePage />}
      />
    </Routes>
  );
};

export default AppRouter;
